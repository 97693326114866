import React, { useState, useMemo } from "react";
import { graphql, PageProps } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import { Layout } from "components/Layout";
import { AnnouncementHeading } from "components/AnnouncementHeading";

const LAST = 6;

export default function News({ data }: PageProps<Queries.NewsCollectionQuery>) {
    const allNews = data.allNews.edges;
    const [page, setPage] = useState(1);
    const { t } = useTranslation();
    const NewsList = useMemo(
        () =>
            allNews.slice(0, LAST * page).map(({ node }) => (
                <li key={node.id} className="border-b-5">
                    <Link
                        to={`/news/${node.frontmatter?.slug}`}
                        className="flex pb-10 flex-col-reverse lg:flex-row lg:items-center lg:pt-4 lg:pb-8 lg:gap-4"
                    >
                        <div className="block">
                            <time className="text-sm tracking-wider lg:text-2xl italic font-bold block my-2.5">
                                {node.frontmatter?.published}
                            </time>
                            <h2 className="font-bold my-5 lg:text-2xl">
                                {node.frontmatter?.title}
                            </h2>
                        </div>
                    </Link>
                </li>
            )),
        [page, allNews]
    );
    const handleViewMore = () => {
        if (page * LAST < allNews.length) {
            setPage(page + 1);
        }
    };

    return (
        <Layout>
            <div className="mx-auto max-w-wide">
                <AnnouncementHeading />
                <div className="bg-gradient-100 lg:bg-gradient-200 p-4 pt-1 pb-10 lg:pb-28 page-trailer">
                    <h3 className="text-center font-bold pb-20 text-4xl">
                        {t("title.past_announcement")}
                    </h3>
                    <ul className="border-t-5 max-w-classic mx-auto">
                        {NewsList}
                    </ul>
                    <div
                        className={cx("text-center mt-24 lg:mt-44", {
                            invisible: page * LAST >= allNews.length,
                        })}
                    >
                        <ViewMore onClick={handleViewMore} />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

const ViewMore: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    className,
    ...props
}) => {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <button
            type="button"
            className={cx(
                "font-bold text-white text-2xl lg:text-3.5xl italic py-5 px-16 lg:py-6 lg:px-20 uppercase bg-black",
                className
            )}
            {...props}
        >
            View More
        </button>
    );
};
export const query = graphql`
    query NewsCollection(
        $language: String!
        $filter: MarkdownRemarkFilterInput!
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ...Translation
                }
            }
        }
        allNews: allMarkdownRemark(
            filter: $filter
            sort: { fields: frontmatter___published, order: DESC }
        ) {
            edges {
                node {
                    id
                    fields {
                        language
                    }
                    frontmatter {
                        title
                        type
                        slug
                        published(formatString: "YYYY.MM.DD")
                    }
                }
            }
        }
    }
`;

export { Head } from "components/GCSRewriteURL";
